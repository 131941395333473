import firebase from "firebase/app";
import { db } from "@/firebase/firebaseConfig";

import server from "@/helpers/apiServer";

import { getMangoFields } from "../common/mango-custom-fields.js";
import { getFundGrubeFields } from "../common/fund-grube-custom-fields.js";

import caseStatuses from "@/data/cases/lists/caseStatuses";

import callTypes from "@/data/cases/lists/callTypes.json";
import seatMagistrates from "@/data/cases/lists/seatMagistrates.json";
import prosecutionMagistrates from "@/data/cases/lists/prosecutionMagistrates.json";
import aggravatedRobberyTypes from "@/data/cases/lists/aggravatedRobberyTypes.json";

import witnessTypes from "@/data/cases/lists/witnessTypes.json";
import recoveredProducts from "@/data/cases/lists/recoveredProducts.json";
import fcses from "@/data/cases/lists/fcses.json";
import crimeTypes from "@/data/cases/lists/crimeTypes.json";
import processings from "@/data/cases/lists/processings.json";
import penalTypes from "@/data/cases/lists/penalTypes.json";
import trialStatuses from "@/data/cases/lists/trialStatuses.json";
import judgmentTypes from "@/data/cases/lists/judgmentTypes.json";
import fcseReasons from "@/data/cases/lists/fcseReasons.json";
import complaintPlaces from "@/data/cases/lists/complaintPlaces.json";
import complaintHolders from "@/data/cases/lists/complaintHolders.json";
import complaintTypes from "@/data/cases/lists/complaintTypes.json";
import trialActions from "@/data/cases/lists/trialActions.json";
import complaintRoles from "@/data/cases/lists/complaintRoles.json";
import towns from "@/data/generic/municipios.json";

import enforcementPaymentMethods from "@/data/cases/lists/enforcementPaymentMethod";
import enforcementSentenceCompliances from "@/data/cases/lists/enforcementSentenceCompliance";

import actions from "@/data/cases/lists/actions.json";
import actionTypes from "@/data/cases/lists/actionTypes.json";

import bandsFormConfig from "@/helpers/bandsFormConfig";
import criminalsFormConfig from "@/helpers/criminalsFormConfig";
import vehiclesFormConfig from "@/helpers/vehiclesFormConfig";

import { createRelatedCasesColumns } from "@/helpers/utils";

const SPAIN_COUNTRY_ID = "0grrHJy5BuGAJ8OdVlZt";
const FRANCE_COUNTRY_ID = "upkhhybjoydnapso";

const AGGRAVATED_ROBBERY_ID = "rrlqurdpydpwucrr";
const WITNESS_TYPE_VIGILANT_ID = "GfTXTyowoGqR5UStPm0I";

const NOT_PROCESSED_CASE_STATUS_ID = "bCP6T8LnU7XkvRpwKjZm"; //ID de estado de caso "no tramitado"

let CURRENT_COUNTRY = SPAIN_COUNTRY_ID;

const getCountry = () => {
  return CURRENT_COUNTRY;
};

const updateCountry = (self, data) => {
  if (
    self.allCustomers &&
    self.allEstablishments &&
    data.establishment &&
    data.establishment.id
  ) {
    let e =
      data.establishment.id === "empresa" //Si es una empresa, usamos el país del cliente
        ? null
        : self.allEstablishments.find((x) => x.id === data.establishment.id);

    if (!(e && e.country)) {
      //Si no encontramos el establecimiento o no tiene país usamos el del cliente
      e = self.allCustomers.find((x) => x.id === data.customer.id);
    }

    if (e && e.country) {
      console.log("PAIS ACTUALIZADO...", e.country.alias);
    }

    const country = e && e.country ? e.country.id : undefined;

    CURRENT_COUNTRY = country || SPAIN_COUNTRY_ID; //Ponemos españa por defecto
  } else {
    CURRENT_COUNTRY = SPAIN_COUNTRY_ID;
  }
};

const fcsesOptionsFunction = () => {
  const countryId = getCountry();

  return countryId ? fcses.filter((f) => f.countryId === countryId) : [];
};

const fcsesDisabledFunction = (data) => {
  return !data.establishment;
};

const searchCourts = async (val) => {
  const countryId = getCountry();

  const filter = { countryId };
  const response = await server.find("courts", {
    text: val,
    page: 1,
    perPage: 50,
    filter,
  });

  return response.data.map(({ id, alias }) => ({ id, alias }));
};

const removeDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const searchTowns = (val) => {
  if (val.length >= 3) {
    const searchedTowns = towns.filter(
      (t) =>
        removeDiacritics(t.name).toLowerCase().indexOf(val.toLowerCase()) > -1
    );
    return searchedTowns.map((x) => ({
      id: x.id,
      alias: x.name,
    }));
  } else {
    return [];
  }
};

const createDecathlonFormConfig = (self, role) => {
  let fields1 = [];
  let fields2 = [];
  let fields3 = [];
  let fields4 = [];
  let fields5 = [];
  let fields6 = [];

  fields1.push({
    size: "1/3",
    type: "datetime",
    attribute: "datetime",
    validation: "required",
  });
  fields1.push({
    size: "1/3",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: {
      optionsFunction() {
        return self.customers
          ? self.customers.filter((c) =>
              c.casesTypes.map((c) => c.id).includes("penal")
            )
          : [];
      },
    },
    onChange: (data) => updateCountry(self, data),
    readonly: role === "customer" || role === "supercustomer",
  });
  fields1.push({
    size: "1/3",
    type: "select",
    attribute: "establishment",
    validation: "required",
    extra: {
      options: [],
    },
    dependencies: ["customer"],
    onDependencyChanged(data) {
      const newOptions = self.allEstablishments
        .filter(
          (x) => data && data.customer && x.customer.id === data.customer.id
        )
        .map((x) => {
          return x.id === "empresa"
            ? { ...x, alias: self.$t("establishments.labels.wholeCompany") }
            : x;
        });

      this.extra.options = newOptions;
    },
    onChange: (data) => updateCountry(self, data),
    visibility(data) {
      return !!data.customer;
    },
  });

  fields2.push({
    size: "1/1",
    type: "text",
    attribute: "facts",
    validation: "required",
  });

  fields3.push({
    size: "1/3",
    type: "select",
    attribute: "fcse",
    validation: "required",
    extra: {
      options: [],
    },
    dependencies: ["customer", "establishment"],
    onDependencyChanged() {
      const newOptions = fcsesOptionsFunction();
      this.extra.options = newOptions;
    },
    disabledFunction: fcsesDisabledFunction,
  });
  fields3.push({
    size: "1/3",
    type: "boolean",
    attribute: "complaintAtEstablishment",
    validation: "required",
    visibility(data) {
      return !!data.fcse;
    },
  });
  fields3.push({
    size: "1/3",
    type: "input",
    attribute: "attestation",
    validation: "required",
    visibility(data) {
      return data.complaintAtEstablishment;
    },
  });

  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "trialSet",
    validation: "required",
  });
  fields4.push({
    size: "1/3",
    type: "datetime",
    attribute: "trialDatetime",
    validation: "required",
    visibility(data) {
      return data.trialSet;
    },
  });
  fields4.push({
    size: "1/4",
    type: "select",
    attribute: "court",
    validation: "required",
    extra: {
      options: [],
      async search(val) {
        this.options = await searchCourts(val);
      },
    },
    visibility(data) {
      return data.trialSet;
    },
  });
  fields4.push({
    size: "1/12",
    type: "input",
    attribute: "courtRoom",
    visibility(data) {
      return data.trialSet;
    },
  });

  fields5.push({
    size: "1/3",
    type: "boolean",
    attribute: "ticket",
    validation: "required",
  });
  fields5.push({
    size: "1/3",
    type: "number",
    attribute: "ticketAmount",
    validation: "required",
    visibility(data) {
      return data.ticket;
    },
  });

  fields6.push({
    size: "1/3",
    type: "enum",
    attribute: "recoveredProducts",
    validation: "required",
    extra: {
      enumType: "recoveredProducts",
      options: recoveredProducts,
      customSort: () => 1, //Orden establecido por nosotros
    },
  });

  return [fields1, fields2, fields3, fields4, fields5, fields6].map(
    (attributes) => ({ attributes })
  );
};

const createDefaultFormConfig = (self, role, isForCreate) => {
  const blocks = [];
  let readonly = !isForCreate && ["customer", "supercustomer"].includes(role);

  //-------------- BLOQUE 1 --------------
  let fields1 = [];
  if (isForCreate) {
    fields1.push({
      size: "1/3",
      type: "select",
      attribute: "customer",
      validation: "required",
      extra: {
        optionsFunction() {
          return self.allCustomers.filter((c) =>
            c.casesTypes.map((c) => c.id).includes("penal")
          );
        },
      },
      onChange: (data) => updateCountry(self, data),
      readonly: role === "customer" || role === "supercustomer",
    });
    fields1.push({
      size: "1/3",
      type: "select",
      attribute: "establishment",
      validation: "required",
      extra: {
        options: [],
      },
      dependencies: ["customer"],
      onDependencyChanged(data) {
        const newOptions = self.allEstablishments
          .filter(
            (x) => data && data.customer && x.customer.id === data.customer.id
          )
          .map((x) => {
            return x.id === "empresa"
              ? { ...x, alias: self.$t("establishments.labels.wholeCompany") }
              : x;
          });

        this.extra.options = newOptions;
      },
      onChange: (data) => updateCountry(self, data),
      disabledFunction(data) {
        return !data.customer;
      },
    });
  } else {
    fields1.push({
      size: "1/2",
      type: "datetime",
      attribute: "datetime",
      validation: "required",
      readonly,
    });

    fields1.push({
      size: "1/2",
      type: "enum",
      attribute: "caseStatus",
      validation: "required",
      extra: {
        enumType: "caseStatuses",
        options: [],
        customSort(a, b) {
          return a.order - b.order;
        },
      },
      dependencies: ["customer"],
      onDependencyChanged(data) {
        let customerId = data.customer.id;
        const newOptions = caseStatuses.filter(
          (x) => !x.customers || !customerId || x.customers.includes(customerId)
        );
        this.extra.options = newOptions;
      },
      readonly,
    });

    fields1.push({
      size: "1/1",
      type: "text",
      attribute: "notProcessedReason",
      visibility(model) {
        return (
          !!model.caseStatus &&
          model.caseStatus.id === NOT_PROCESSED_CASE_STATUS_ID
        );
      },

      readonly,
    });
  }

  blocks.push(fields1);
  //-------------- BLOQUE 1 --------------

  //-------------- BLOQUE 2 --------------
  let fields2 = [];
  if (isForCreate) {
    fields2.push({
      size: "1/3",
      type: "datetime",
      attribute: "datetime",
      validation: "required",
    });
  } else {
    fields1.push({
      size: "1/3",
      type: "select",
      attribute: "customer",
      validation: "required",
      extra: {
        optionsFunction() {
          return self.allCustomers.filter((c) =>
            c.casesTypes.map((c) => c.id).includes("penal")
          );
        },
      },
      onChange: (data) => updateCountry(self, data),
      readonly: true,
    });
    fields1.push({
      size: "1/3",
      type: "select",
      attribute: "establishment",
      validation: "required",
      extra: {
        options: [],
      },
      dependencies: ["customer"],
      onDependencyChanged(data) {
        const newOptions = self.allEstablishments
          .filter(
            (x) => data && data.customer && x.customer.id === data.customer.id
          )
          .map((x) => {
            return x.id === "empresa"
              ? { ...x, alias: self.$t("establishments.labels.wholeCompany") }
              : x;
          });

        this.extra.options = newOptions;
      },
      onChange: (data) => updateCountry(self, data),
      disabledFunction(data) {
        return !data.customer;
      },
      readonly,
    });
    fields1.push({
      size: "1/3",
      type: "select",
      attribute: "lawyer",
      validation: "required",
      extra: {
        optionsFunction() {
          return self.allLawyers;
        },
      },
      readonly,
    });
  }

  fields2.push({
    size: "1/1",
    type: "text",
    attribute: "facts",
    validation: "required",
    readonly: !isForCreate && ["customer"].includes(role),
  });

  blocks.push(fields2);
  //-------------- BLOQUE 2 --------------

  //-------------- BLOQUE 3 --------------
  let fields3 = [];
  fields3.push({
    size: "1/3",
    type: "select",
    attribute: "fcse",
    // validation: "required",
    extra: { options: [] },
    dependencies: ["customer", "establishment"],
    onDependencyChanged() {
      const newOptions = fcsesOptionsFunction();
      this.extra.options = newOptions;
    },

    disabledFunction: fcsesDisabledFunction,
    readonly,
  });

  fields3.push({
    size: "1/3",
    type: "boolean",
    attribute: "complaintAtEstablishment",
    validation: "required",
    visibility(model) {
      return !!model.fcse;
    },
    readonly,
  });

  fields3.push({
    size: "1/3",
    type: "input",
    attribute: "attestation",
    validation: "required",
    visibility(model) {
      return !!model.fcse && model.complaintAtEstablishment;
    },
    readonly,
  });

  blocks.push(fields3);
  //-------------- BLOQUE 3 --------------

  //-------------- BLOQUE 4 --------------
  let fields4 = [];
  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "trialSet",
    validation: "required",
    readonly,
  });
  fields4.push({
    size: "1/3",
    type: "datetime",
    attribute: "trialDatetime",
    validation: "required",
    visibility(model) {
      return model.trialSet;
    },
    readonly,
  });
  fields4.push({
    size: "3/12",
    type: "select",
    attribute: "court",
    extra: {
      options: [],
      async search(val) {
        this.options = await searchCourts(val);
      },
    },
    validation: "required",
    visibility(model) {
      return model.trialSet;
    },
    readonly,
  });
  fields4.push({
    size: "1/12",
    type: "input",
    attribute: "courtRoom",
    visibility(model) {
      return model.trialSet;
    },
    readonly,
  });

  blocks.push(fields4);
  //-------------- BLOQUE 4 --------------

  //-------------- BLOQUE 5 --------------
  let fields5 = [];
  fields5.push({
    size: "1/2",
    type: "boolean",
    attribute: "ticket",
    validation: "required",
    readonly,
  });
  fields5.push({
    size: "1/2",
    type: "number",
    attribute: "ticketAmount",
    validation: "required",
    visibility(data) {
      return data.ticket;
    },
    readonly,
  });
  fields5.push({
    size: "1/2",
    type: "boolean",
    attribute: "minors",
    validation: "required",
    readonly,
  });

  fields5.push({
    size: "1/2",
    type: "enum",
    attribute: "recoveredProducts",
    validation: "required",
    extra: {
      enumType: "recoveredProducts",
      options: recoveredProducts,
      customSort: () => 1, //Orden establecido por nosotros
    },
    readonly,
  });

  blocks.push(fields5);
  //-------------- BLOQUE 5 --------------

  const extraBlocks = [
    getMangoFields(self.$store.state.AppActiveUser),
    getFundGrubeFields({}),
  ];

  extraBlocks.forEach((b) =>
    b.forEach((fs) => fs.forEach((f) => (f.readonly = readonly)))
  );

  extraBlocks.filter((x) => x && x.length).forEach((x) => blocks.push(...x));

  //-------------- BLOQUE 6 --------------
  const fields6 = [];
  fields6.push({
    size: "1/3",
    type: "enum",
    attribute: "processing",
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    extra: { enumType: "processings", options: processings },
    readonly,
  });
  fields6.push({
    size: "1/3",
    type: "multiple-enum",
    attribute: "penalType",
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    extra: {
      enumType: "penalTypes",
      options: [],
    },
    dependencies: ["customer", "establishment"],
    onDependencyChanged() {
      const newOptions = penalTypes.filter(
        (x) => !x.country || x.country === getCountry()
      );
      this.extra.options = newOptions;
    },
    readonly,
  });

  fields6.push({
    size: "1/3",
    type: "enum",
    attribute: "crimeType",
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    extra: {
      enumType: "crimeTypes",
      options: crimeTypes,
    },
    readonly,
  });

  if (!isForCreate) {
    fields6.push({
      size: "1/3",
      type: "boolean",
      attribute: "preimplantation",
      validation: "required",
      readonly,
    });
  }

  fields6.push({
    size: "1/3",
    type: "enum",
    attribute: "aggravatedRobberyType",
    extra: {
      enumType: "aggravatedRobberyTypes",
      options: aggravatedRobberyTypes,
    },
    hideOnInvisible: true,
    visibility: (data) => {
      if (getCountry() === FRANCE_COUNTRY_ID) {
        const penalType = (
          Array.isArray(data.penalType)
            ? data.penalType
            : data.penalType
            ? [data.penalType]
            : []
        ).map((x) => x.id);
        return penalType.includes(AGGRAVATED_ROBBERY_ID); //Es robo agravado
      } else {
        return false;
      }
    },
    readonly,
  });

  blocks.push(fields6);
  //-------------- BLOQUE 6 --------------

  if (!isForCreate) {
    editTableFields(self, role).forEach((x) => blocks.push(x));

    if (!["customer"].includes(role)) {
      blocks.push([
        {
          size: "1/1",
          type: "list",
          attribute: "relatedCases",
          extra: relatedCasesConfig(self),
        },
      ]);
    }
  }

  return blocks.map((attributes) => ({
    attributes,
  }));
};

const editTableFields = (self, role) => {
  const formConfigs = {
    Criminal: criminalsFormConfig,
    Band: bandsFormConfig,
    Vehicle: vehiclesFormConfig,
  };
  const config = [
    {
      singleName: "Criminal",
      uniqueKey: "dni",
      attributes: ["dni", "name", "surname", "nickname"],
      title: (x) => `${x.name} ${x.surname} ${x.dni ? `(${x.dni})` : ""}`,
    },
    {
      singleName: "Band",
      uniqueKey: "alias",
      attributes: ["alias"],
      title: (x) => `${x.alias}`,
    },
    {
      singleName: "Vehicle",
      uniqueKey: "plate",
      attributes: ["plate", "brand", "model", "color"],
      title: (x) => `${x.brand} - ${x.model} (${x.plate})`,
    },
  ];
  const tableFields = config.map(
    ({ singleName, uniqueKey, attributes, title }) => {
      let name = `${singleName}s`;
      let collectionName = name.toLowerCase();
      let fields = formConfigs[singleName];
      let columns = [];
      attributes.forEach((a) => {
        columns.push({
          headerKey: `${collectionName}.fields.${a}`,
          filter: false,
          width: 100,
          field: a,
        });
      });

      return [
        {
          size: "1/1",
          type: "list",
          attribute: collectionName,
          extra: listConfig(
            self,
            role,
            name,
            collectionName,
            title,
            uniqueKey,
            fields,
            columns
          ),
        },
      ];
    }
  );
  return tableFields;
};

const listConfig = (
  self,
  role,
  name,
  collectionName,
  title,
  uniqueKey,
  fields,
  columns
) => {
  let onAdd = async (id) => {
    let relationToAdd = { caseId: self.entity.id, entityId: id };
    await db.collection(`cases${name}`).add(relationToAdd);
  };
  let onRemove = async (id) => {
    await db
      .collection(`cases${name}`)
      .where("entityId", "==", id)
      .get()
      .then((x) => x.docs.forEach((y) => y.ref.delete()));
  };
  let filterOptions = () => {
    return (self[`notCase${name}`] || []).map((e) => ({
      title: title(e),
      ...e,
    }));
  };
  let data = () => {
    return self[`case${name}`];
  };
  let prevalidate = async (data, collection, errors) => {
    if (data[uniqueKey]) {
      let snapshot = await collection
        .where(uniqueKey, "==", data[uniqueKey])
        .get();

      if (snapshot.docs && snapshot.docs.length > 0) {
        errors.add({
          field: uniqueKey,
          msg: self.$t(`Validations.${collectionName}.${uniqueKey}.unique`),
        });
        return true;
      }
    }

    if (uniqueKey === "plate") {
      if (!/^[0-9]{4}[A-Z]{3}$/.test(data.plate)) {
        errors.add({
          field: "plate",
          msg: self.$t(`Validations.${collectionName}.plate.invalid`),
        });
        return true;
      }
    }

    return false;
  };

  if (!["supercustomer", "customer"].includes(role)) {
    columns.push({
      headerName: "",
      filter: false,
      width: 50,
      field: "id",
      cellRendererFramework: "CellRendererRemove",
      onRemove,
    });
  }

  let filters = [];
  if (!["supercustomer", "customer"].includes(role)) {
    filters.push({
      size: "1/5",
      mobileSize: "1/2",
      select: true,
      placeholder: `cases.placeholders.${collectionName}`,
      label: "title",
      clearable: false,
      resetOnOptionsChange: true,
      onChange: (e) => {
        if (e) {
          //Cuando reseteamos nos envía null
          onAdd(e.id);
        }
      },
      options: filterOptions,
    });
    filters.push({
      size: "4/5",
      mobileSize: "1/2",
      sidebar: true,
      i18n: `${collectionName}.buttons.new`,
      extraClasses: "text-right",
      sidebarConfig: {
        title: `${collectionName}.buttons.new`,
        collectionName: collectionName,
        attributes: fields,
        prevalidate,
        afterCreate: onAdd,
      },
    });
  }

  return {
    data,
    filters,
    gridConfig: {
      components: {},
      gridOptions: {},
      columnDefs: columns,
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      onRowClicked: () => {},
    },
  };
};

const relatedCasesConfig = (self) => {
  let columns = createRelatedCasesColumns(self).filter(
    (x) => !["customer", "isDirect"].includes(x.field)
  );

  let onAdd = (id) => {
    db.collection(`relatedCases`).add({
      case1: self.$route.params.id,
      case2: id,
    });
  };
  let onRemove = (id) => {
    db.collection(`relatedCases`).doc(id).delete();
  };

  let filterOptions = () => {
    return self.allCases
      .filter(
        (x) =>
          x.customer.id === self.entity.customer.id && self.entity.id !== x.id
      )
      .filter((x) => !self.relatedCases.map((x) => x.caseId).includes(x.id))
      .map((e) => Object.assign({ id: e.id, title: e.cid }, e));
  };

  columns.push({
    headerName: "",
    filter: false,
    width: 50,
    field: "id",
    cellRendererFramework: "CellRendererRemove",
    onRemove,
  });

  let filters = [];
  filters.push({
    size: "1/5",
    mobileSize: "1/2",
    select: true,
    placeholder: `cases.placeholders.relatedCases`,
    label: "title",
    clearable: false,
    resetOnOptionsChange: true,
    onChange: (e) => {
      if (e) {
        //Cuando reseteamos nos envía null
        onAdd(e.id);
      }
    },
    options: filterOptions,
  });

  return {
    data() {
      return self.relatedCases;
    },
    filters,
    gridConfig: {
      components: {},
      gridOptions: {},
      columnDefs: columns,
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      onRowClicked: () => {},
    },
  };
};

const editFilesTab = (self, role) => {
  let fields1 = [];
  fields1.push({
    size: "1/1",
    type: "alert",
    color: "danger",
    icon: "warning",
    hideLabel: true,
    text: self.$t("messages.errors.noScannedTicket"),
    visibility(model) {
      return model.ticket && !(self.files || []).length;
    },
  });
  fields1.push({
    size: "1/1",
    type: "files",
    attribute: "files",
    hideLabel: true,
    extra: {
      listingTemplate: ["admin", "manager", "lawyer"].includes(role)
        ? "managerFilesListing"
        : undefined,
      files: () => {
        return self.files;
      },
      relationKey: "case",
      config: {
        url: "/",
        uploadMultiple: true,
        parallelUploads: 5,
        method: "put",
        autoQueue: false,
        maxFilesize: 2048,
        dictDefaultMessage: self.$t("messages.filesDragAnddrop"),
      },
    },
  });

  return [fields1].map((attributes) => ({ attributes }));
};

const editWitnessesTab = (self, role) => {
  let fields1 = [];

  let attributes = [];

  attributes.push({
    type: "input",
    validation: "required|alpha_spaces",
    attribute: "name",
  });
  attributes.push({
    type: "input",
    validation: "required|alpha_spaces",
    attribute: "lastname",
  });
  attributes.push({ type: "input", attribute: "code" });
  attributes.push({ type: "input", validation: "email", attribute: "email" });
  attributes.push({
    type: "input",
    validation: "digits:9",
    attribute: "mobile",
  });
  attributes.push({
    type: "enum",
    validation: "required",
    attribute: "witnessType",
    extra: {
      enumType: "witnessTypes",
      options: witnessTypes,
      customSort(a, b) {
        return a.order - b.order;
      },
    },
  });
  attributes.push({
    type: "input",
    attribute: "nTip",
    visibility(model) {
      return (
        model.witnessType && model.witnessType.id === WITNESS_TYPE_VIGILANT_ID
      );
    },
  });

  let collectionName = "witnesses";

  let filters = [];
  filters.push({
    size: "1/2",
    mobileSize: "1/1",
    custom: true,
    template: "witnessSearcher",
    data: {},
  });
  filters.push({
    size: "1/2",
    mobileSize: "1/1",
    sidebar: true,
    i18n: `${collectionName}.buttons.new`,
    extraClasses: "text-right",
    sidebarConfig: {
      title: `${collectionName}.buttons.new`,
      collectionName,
      attributes: attributes,
      prevalidate: async (data, collection, errors) => {
        if (data.code) {
          let snapshot = await collection
            .where("code", "==", data.code)
            .where("customer.id", "==", self.entity.customer.id)
            .get();
          if (snapshot.docs && snapshot.docs.length > 0) {
            errors.add({
              field: "code",
              msg: self.$t(`${collectionName}.validations.code.unique`),
            });
            return true;
          }
        }

        return false;
      },
      afterCreate: (id) => {
        db.collection(collectionName)
          .doc(id)
          .update({
            cases: firebase.firestore.FieldValue.arrayUnion(self.entity.id),
            customer: self.entity.customer,
          });
      },
    },
  });

  let columnDefs = [
    {
      headerName: self.$t(`${collectionName}.columns.code`),
      field: "code",
      filter: false,
      width: 75,
    },
    {
      headerName: self.$t(`${collectionName}.columns.name`),
      field: "name",
      filter: false,
      width: 150,
    },
    {
      headerName: self.$t(`${collectionName}.columns.lastname`),
      field: "lastname",
      cellRenderer: (params) => {
        if (params.data.nTip) {
          return params.data.lastname + ` (${params.data.nTip})`;
        } else {
          return params.data.lastname;
        }
      },
      filter: false,
      width: 150,
    },
    {
      headerName: self.$t(`${collectionName}.columns.email`),
      field: "email",
      filter: false,
      width: 150,
    },
    {
      headerName: self.$t(`${collectionName}.columns.mobile`),
      field: "mobile",
      filter: false,
      width: 75,
    },
    {
      headerName: self.$t(`${collectionName}.columns.injuried`),
      filter: false,
      width: 75,
      cellClass: "text-center",
      cellRenderer: (params) => {
        var injuriedValues = params.data.injuried || [];
        var isChecked = !!injuriedValues.find((i) => i === self.entity.id);
        if (["supercustomer", "customer"].includes(role)) {
          return self.$t(`boolean.${isChecked ? "yes" : "no"}`);
        } else {
          var input = document.createElement("input");
          input.type = "checkbox";
          input.checked = isChecked;
          input.addEventListener("click", function () {
            db.collection("witnesses")
              .doc(params.data.id)
              .update({
                injuried: isChecked
                  ? firebase.firestore.FieldValue.arrayRemove(self.entity.id)
                  : firebase.firestore.FieldValue.arrayUnion(self.entity.id),
              });
          });
          return input;
        }
      },
    },
  ];
  if (!["customer", "supercustomer"].includes(role)) {
    const caseId = self.$route.params.id;
    columnDefs.push({
      headerName: "",
      filter: false,
      field: "id",
      cellClass: "text-center",
      width: 40,
      cellRenderer: (params) => {
        var button = document.createElement("button");
        button.className =
          "vs-component vs-button m-2 p-2 vs-button-danger vs-button-filled includeIcon";
        button.innerHTML = `
          <i class="vs-icon notranslate icon-scale vs-button--icon  feather icon-trash"></i>
`;
        button.addEventListener("click", () => {
          self.$vs.dialog({
            type: "confirm",
            color: "danger",
            title: self.$t("modals.witnessRemoveConfirmation.title"),
            text: self.$t("modals.witnessRemoveConfirmation.description"),
            acceptText: self.$t("buttons.confirm"),
            cancelText: self.$t("buttons.cancel"),
            accept() {
              db.collection("witnesses")
                .doc(params.data.id)
                .update({
                  injuried: firebase.firestore.FieldValue.arrayRemove(caseId),
                  cases: firebase.firestore.FieldValue.arrayRemove(caseId),
                });
            },
          });
        });
        return button;
      },
    });
  }

  fields1.push({
    size: "1/1",
    type: "list",
    extra: {
      data: () => {
        return self.witnesses;
      },
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: () => {},
      },
    },
  });

  return [fields1].map((attributes) => ({ attributes }));
};

const editComplaintTab = (self, role) => {
  let readonly = ["customer", "supercustomer"].includes(role);

  //-------------- BLOQUE 1 --------------
  let fields1 = [];
  fields1.push({
    size: "1/3",
    type: "datetime",
    attribute: "complaintDatetime",
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "enum",
    attribute: "complaintType",
    extra: {
      enumType: "complaintTypes",
      options: complaintTypes,
    },
    hideOnInvisible: true,
    visibility: () => {
      return getCountry() === FRANCE_COUNTRY_ID;
    },
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "attestation",
    readonly,
  });
  //-------------- BLOQUE 1 --------------

  //-------------- BLOQUE 2 --------------
  let fields2 = [];
  fields2.push({
    size: "1/3",
    type: "enum",
    attribute: "complaintHolder",
    extra: {
      enumType: "complaintHolders",
      options: complaintHolders,
    },
    readonly,
  });
  fields2.push({
    size: "1/3",
    type: "select",
    attribute: "complaintLawyer",
    extra: {
      optionsFunction() {
        return self.allLawyers;
      },
    },
    readonly,
  });
  //-------------- BLOQUE 2 --------------

  //-------------- BLOQUE 3 --------------
  let fields3 = [];
  const FCSEId = complaintPlaces.find((x) => x.key === "policeStation").id;
  const isComplaintAtFCSE = (data) => {
    return !!data.complaintPlace && data.complaintPlace.id === FCSEId;
  };
  fields3.push({
    size: "1/3",
    type: "enum",
    attribute: "complaintPlace",
    extra: {
      enumType: "complaintPlaces",
      options: complaintPlaces,
    },
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    readonly,
  });
  fields3.push({
    size: "1/3",
    type: "enum",
    attribute: "fcseReason",
    extra: { enumType: "fcseReasons", options: fcseReasons },
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    visibility: isComplaintAtFCSE,
    readonly,
  });
  fields3.push({
    size: "1/3",
    type: "enum",
    attribute: "complaintRole",
    extra: {
      enumType: "complaintRoles",
      options: complaintRoles,
    },
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    readonly,
  });

  fields3.push({
    size: "1/4",
    type: "select",
    attribute: "town",
    extra: {
      options: [],
      search(val) {
        const newOptions = searchTowns(val);
        this.options = newOptions;
      },
    },
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    visibility: isComplaintAtFCSE,
    readonly,
  });
  fields3.push({
    size: "1/4",
    type: "custom",
    template: "townLocation",
    visibility(data) {
      return isComplaintAtFCSE(data) && data.town;
    },
  });
  fields3.push({
    size: "1/4",
    type: "select",
    attribute: "complaintFcse",
    extra: { options: [] },
    dependencies: ["customer", "establishment"],
    onDependencyChanged() {
      const newOptions = fcsesOptionsFunction();
      this.extra.options = newOptions;
    },
    validation: ["customer", "supercustomer"].includes(role) ? "" : "required",
    disabledFunction: fcsesDisabledFunction,
    visibility(data) {
      return isComplaintAtFCSE(data) && data.town;
    },
    readonly,
  });
  fields3.push({
    size: "1/4",
    type: "input",
    attribute: "policeStation",
    visibility(data) {
      return isComplaintAtFCSE(data) && data.town;
    },
    readonly,
  });
  //-------------- BLOQUE 3 --------------

  //-------------- BLOQUE 4 --------------
  const fields4 = [];
  fields4.push({
    size: "1/1",
    type: "files",
    attribute: "complaintFiles",
    extra: {
      files: () => {
        return self.complaintFiles;
      },
      relationKey: "case",
      config: {
        url: "/",
        uploadMultiple: true,
        parallelUploads: 5,
        method: "put",
        autoQueue: false,
        maxFilesize: 2048,
        dictDefaultMessage: self.$t("messages.filesDragAnddrop"),
      },
    },
    readonly,
  });
  //-------------- BLOQUE 4 --------------

  return [fields1, fields2, fields3, fields4].map((attributes) => ({
    attributes,
  }));
};

const editTrialTab = (self, role) => {
  let readonly = ["customer", "supercustomer"].includes(role);

  //-------------- BLOQUE 1 --------------
  const fields1 = [];
  fields1.push({
    size: "1/3",
    type: "datetime",
    attribute: "trialDatetime",
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "enum",
    attribute: "trialAction",
    extra: {
      enumType: "trialActions",
      options: trialActions,
    },
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "select",
    attribute: "trialLawyer",
    extra: {
      optionsFunction() {
        return self.allLawyers;
      },
    },
    readonly,
  });

  fields1.push({
    size: "1/4",
    type: "select",
    attribute: "courtInstruction",
    extra: {
      options: [],
      async search(val) {
        this.options = await searchCourts(val);
      },
    },
    readonly,
  });
  fields1.push({
    size: "1/12",
    type: "input",
    attribute: "courtInstructionRoom",
    readonly,
  });
  fields1.push({
    size: "1/4",
    type: "select",
    attribute: "courtPenal",
    extra: {
      options: [],
      async search(val) {
        this.options = await searchCourts(val);
      },
    },
    readonly,
  });
  fields1.push({
    size: "1/12",
    type: "input",
    attribute: "courtPenalRoom",
    readonly,
  });
  fields1.push({
    size: "1/4",
    type: "select",
    attribute: "courtAppeal",
    extra: {
      options: [],
      async search(val) {
        this.options = await searchCourts(val);
      },
    },
    readonly,
  });

  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "autosMisdemeanor",
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "autosPreviousProceedings",
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "input",
    attribute: "autosAbbreviatedProcedure",
    readonly,
  });

  //--- Campos para francia
  [
    { key: "callType", enum: "callTypes", data: callTypes },
    { key: "seatMagistrate", enum: "seatMagistrates", data: seatMagistrates },
    {
      key: "prosecutionMagistrate",
      enum: "prosecutionMagistrates",
      data: prosecutionMagistrates,
    },
  ].forEach((d) => {
    fields1.push({
      size: "1/3",
      type: "enum",
      attribute: d.key,
      extra: {
        enumType: d.enum,
        options: d.data,
      },
      hideOnInvisible: true,
      visibility: () => {
        return getCountry() === FRANCE_COUNTRY_ID;
      },
      readonly,
    });
  });
  //--- Campos para francia

  fields1.push({
    size: "1/3",
    type: "enum",
    attribute: "trialStatus",
    extra: {
      enumType: "trialStatuses",
      options: trialStatuses,
    },
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "boolean",
    attribute: "trialAccordance",
    readonly,
  });
  fields1.push({
    size: "1/3",
    type: "enum",
    attribute: "judgmentType",
    extra: {
      enumType: "judgmentTypes",
      options: judgmentTypes,
    },
    readonly,
  });
  fields1.push({
    size: "1/1",
    type: "text",
    attribute: "notGuiltySentenceReason",
    visibility(data) {
      return (
        !!data.judgmentType && data.judgmentType.id === "IZgr5sCvF7xDgVYH7Ppa"
      ); //Condena Absolutoria
    },
    readonly,
  });
  //-------------- BLOQUE 1 --------------

  //-------------- BLOQUE 2 --------------
  const fields2 = [];
  const caseId = self.$route.params.id;
  fields2.push({
    size: "1/1",
    type: "multiEdit",
    key: "adjournments",
    config: {
      columns: [
        { key: "date", type: "datetime", size: "1/4", validation: "required" },
        { key: "reason", type: "input", size: "3/4" },
      ],
      readonly() {
        return readonly;
      },
      entities() {
        return self.adjournments;
      },
      prefix: "cases.adjournments",
      async onAdd(model) {
        const currentUser = {
          id: self.$store.state.AppActiveUser.uid,
          email: self.$store.state.AppActiveUser.email,
        };

        const newAdjournment = {
          date: firebase.firestore.Timestamp.fromDate(
            model.trialDatetime ? new Date(model.trialDatetime) : new Date()
          ),
          reason: "",
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: currentUser,
        };
        const dataToUpdate = Object.assign(
          {
            updatedAt: firebase.firestore.Timestamp.now(),
            updatedBy: currentUser,
          },
          { trialDatetime: null }
        );

        await Promise.all([
          db.collection(`/cases/${caseId}/adjournments`).add(newAdjournment),
          db.collection("cases").doc(caseId).update(dataToUpdate),
        ]);
      },
      onRemove(id) {
        db.collection(`/cases/${caseId}/adjournments`).doc(id).delete();
      },
      onChange(id, attribute, value) {
        if (attribute === "date" && !!value) {
          value = firebase.firestore.Timestamp.fromDate(new Date(value));
        }
        let dataToUpdate = {};
        dataToUpdate[attribute] = value;
        db.collection(`/cases/${caseId}/adjournments`)
          .doc(id)
          .update(dataToUpdate);
      },
    },
  });
  //-------------- BLOQUE 2 --------------

  //-------------- BLOQUE 3 --------------
  const fields3 = [];
  fields3.push({
    size: "1/1",
    type: "text",
    attribute: "judgment",
    readonly,
  });
  //-------------- BLOQUE 3 --------------

  //-------------- BLOQUE 4 --------------
  const fields4 = [];
  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "penalty",
    readonly,
  });
  fields4.push({
    size: "1/3",
    type: "number",
    attribute: "penaltyAmount",
    visibility(data) {
      return data.penalty;
    },
    readonly,
  });
  fields4.push({ size: "1/3", type: "separator" });

  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "prison",
    readonly,
  });
  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "restrainingOrder",
    readonly,
  });
  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "facialRecognition",
    readonly,
  });
  fields4.push({
    size: "1/1",
    type: "text",
    attribute: "restrainingOrderExplanation",
    visibility(data) {
      return data.restrainingOrder;
    },
    readonly,
  });

  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "companyCivilLiability",
    readonly,
  });
  fields4.push({
    size: "1/3",
    type: "number",
    attribute: "companyCivilLiabilityAmount",
    visibility(data) {
      return data.companyCivilLiability;
    },
    readonly,
  });
  fields4.push({ size: "1/3", type: "separator" });

  fields4.push({
    size: "1/3",
    type: "boolean",
    attribute: "injuriesCivilLiability",
    readonly,
  });
  fields4.push({
    size: "1/3",
    type: "number",
    attribute: "injuriesCivilLiabilityAmount",
    visibility(data) {
      return data.injuriesCivilLiability;
    },
    readonly,
  });
  fields4.push({ size: "1/3", type: "separator" });

  //-------------- BLOQUE 4 --------------

  //-------------- BLOQUE 5 --------------
  const fields5 = [];
  fields5.push({
    size: "1/1",
    type: "files",
    attribute: "judgmentFiles",
    readonly,
    extra: {
      files: () => {
        return self.judgmentFiles;
      },
      relationKey: "case",
      config: {
        url: "/",
        uploadMultiple: true,
        parallelUploads: 5,
        method: "put",
        autoQueue: false,
        maxFilesize: 2048,
        dictDefaultMessage: self.$t("messages.filesDragAnddrop"),
      },
    },
  });

  //-------------- BLOQUE 5 --------------

  //-------------- BLOQUE 6 --------------
  const fields6 = [];
  const PRISION_SENTENCE_ID = "WsWoGimD3VbHTRrOyWfv"; //ID de sentencia "Ingreso a prisión"

  fields6.push({
    size: "1/2",
    attribute: "enforcementNumber",
    type: "input",
    readonly,
  });
  fields6.push({
    size: "1/2",
    attribute: "enforcementCourt",
    type: "select",
    extra: {
      options: [],
      async search(val) {
        this.options = await searchCourts(val);
      },
    },
    readonly,
  });
  fields6.push({
    size: "1/2",
    attribute: "enforcementPayment",
    type: "boolean",
    readonly,
  });
  fields6.push({
    size: "1/2",
    attribute: "enforcementPaymentMethod",
    type: "enum",
    extra: {
      enumType: "enforcementPaymentMethods",
      options: enforcementPaymentMethods,
    },
    readonly,
  });
  fields6.push({
    size: "1/3",
    attribute: "enforcementSentenceCompliance",
    type: "enum",
    extra: {
      enumType: "enforcementSentenceCompliances",
      options: enforcementSentenceCompliances,
    },
    readonly,
  });
  fields6.push({
    size: "1/3",
    attribute: "enforcementRestrainingOrderStartDate",
    type: "date",
    readonly,
  });
  fields6.push({
    size: "1/3",
    attribute: "enforcementRestrainingOrderEndDate",
    type: "date",
    readonly,
  });
  fields6.push({
    size: "1/2",
    attribute: "enforcementPrisionStartDate",
    type: "date",
    readonly,
    visibility(data) {
      return (
        !!data.enforcementSentenceCompliance &&
        data.enforcementSentenceCompliance.id === PRISION_SENTENCE_ID
      );
    },
  });
  fields6.push({
    size: "1/2",
    attribute: "enforcementPrisionEndDate",
    type: "date",
    readonly,
    visibility(data) {
      return (
        !!data.enforcementSentenceCompliance &&
        data.enforcementSentenceCompliance.id === PRISION_SENTENCE_ID
      );
    },
  });

  //-------------- BLOQUE 6 --------------

  return [fields1, fields2, fields3, fields4, fields5, fields6].map(
    (attributes) => ({
      attributes,
    })
  );
};

const editActionsTab = (self, role) => {
  const readonly = ["customer", "supercustomer"].includes(role);
  const fields1 = [];
  const caseId = self.$route.params.id;
  fields1.push({
    size: "1/1",
    type: "multiEdit",
    key: "actions",
    config: {
      columns: [
        { key: "date", type: "date", size: "1/6", validation: "required" },
        {
          key: "type",
          type: "enum",
          size: "1/3",
          extra: { enumType: "actionTypes", options: actionTypes },
        },
        {
          key: "action",
          type: "enum",
          size: "1/2",
          extra: {
            enumType: "actions",
            optionsFunction(model, entity) {
              return entity.type
                ? actions.filter((a) => a.type === entity.type.id)
                : [];
            },
          },
        },
      ],
      readonly() {
        return readonly;
      },
      entities() {
        return self.actions;
      },
      prefix: "cases.actions",
      async onAdd(model) {
        const currentUser = {
          id: self.$store.state.AppActiveUser.uid,
          email: self.$store.state.AppActiveUser.email,
        };

        const newAction = {
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          createdAt: firebase.firestore.Timestamp.now(),
          customerId: model.customer.id,
          establishmentId: model.establishment.id,
          cid: model.cid,
          createdBy: currentUser,
        };

        await db.collection(`/cases/${caseId}/actions`).add(newAction);
      },
      onRemove(id) {
        db.collection(`/cases/${caseId}/actions`).doc(id).delete();
      },
      onChange(id, attribute, value) {
        let isChanged = true;
        if (attribute === "date" && !!value) {
          value = firebase.firestore.Timestamp.fromDate(new Date(value));
          //Caso especial de fecha que me llegan datos aunque no haya cambiado
          let oldValue = self.actions.find((a) => a.id === id)[attribute];
          isChanged = value.seconds !== oldValue.seconds;
        }

        if (isChanged) {
          let dataToUpdate = {};
          dataToUpdate[attribute] = value;

          if (attribute === "type") {
            dataToUpdate.action = null;
          }

          db.collection(`/cases/${caseId}/actions`)
            .doc(id)
            .update(dataToUpdate);
        }
      },
    },
  });

  fields1.push({
    size: "1/1",
    type: "multiEdit",
    key: "colaboratorsActions",
    config: {
      columns: [
        {
          type: "many",
          size: "1/3",
          fields: [
            { key: "date", type: "date", validation: "required" },
            {
              key: "lawyer",
              type: "select",
              extra: {
                optionsFunction() {
                  return self.allLawyers;
                },
              },
            },
          ],
        },
        {
          type: "many",
          size: "1/2",
          fields: [
            {
              key: "type",
              type: "enum",
              extra: { enumType: "actionTypes", options: actionTypes },
            },
            {
              key: "action",
              type: "enum",
              extra: {
                enumType: "actions",
                optionsFunction(model, entity) {
                  return entity.type
                    ? actions.filter((a) => a.type === entity.type.id)
                    : [];
                },
              },
            },
          ],
        },
        { type: "number", size: "1/6", key: "price" },
      ],
      readonly() {
        return readonly;
      },
      entities() {
        return self.colaboratorsActions;
      },
      prefix: "cases.colaboratorsActions",
      async onAdd(model) {
        const currentUser = {
          id: self.$store.state.AppActiveUser.uid,
          email: self.$store.state.AppActiveUser.email,
        };

        const newAction = {
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          createdAt: firebase.firestore.Timestamp.now(),
          customerId: model.customer.id,
          establishmentId: model.establishment.id,
          cid: model.cid,
          createdBy: currentUser,
        };

        await db
          .collection(`/cases/${caseId}/colaboratorsActions`)
          .add(newAction);
      },
      onRemove(id) {
        db.collection(`/cases/${caseId}/colaboratorsActions`).doc(id).delete();
      },
      onChange(id, attribute, value) {
        let isChanged = true;
        if (attribute === "date" && !!value) {
          value = firebase.firestore.Timestamp.fromDate(new Date(value));
          //Caso especial de fecha que me llegan datos aunque no haya cambiado
          let oldValue = self.colaboratorsActions.find((a) => a.id === id)[
            attribute
          ];
          isChanged = value.seconds !== oldValue.seconds;
        }

        if (isChanged) {
          let dataToUpdate = {};
          dataToUpdate[attribute] = value;

          if (attribute === "type") {
            dataToUpdate.action = null;
          }
          if (attribute === "price") {
            dataToUpdate[attribute] = isNaN(parseFloat(value))
              ? null
              : parseFloat(value);
          }

          db.collection(`/cases/${caseId}/colaboratorsActions`)
            .doc(id)
            .update(dataToUpdate);
        }
      },
    },
  });

  return [fields1].map((attributes) => ({ attributes }));
};

const editFormConfig = (self, role) => {
  let generalRows = createDefaultFormConfig(self, role, false);
  let witnessesRows = editWitnessesTab(self, role);
  let fileRows = editFilesTab(self, role);
  let complaintRows = editComplaintTab(self, role);
  let trialRows = editTrialTab(self, role);
  let actionsRows = editActionsTab(self, role);

  return [
    { name: "general", rows: generalRows },
    { name: "witnesses", rows: witnessesRows },
    { name: "files", rows: fileRows },
    { name: "complaint", rows: complaintRows },
    { name: "trial", rows: trialRows },
    ...(!["customer", "supercustomer"].includes(role)
      ? [{ name: "actions", rows: actionsRows }]
      : []),
  ];
};

let formConfig = (self, role, isForCreate) => {
  console.log("EN EL FORM CONFIG...");
  // updateCountry(self, model);
  if (isForCreate) {
    if (
      self.$store.state.AppActiveUser.customer &&
      self.$store.state.AppActiveUser.customer.alias.trim() === "DECATHLON"
    ) {
      return createDecathlonFormConfig(self, role);
    } else {
      return createDefaultFormConfig(self, role, true);
    }
  } else {
    return editFormConfig(self, role);
  }
};

export default formConfig;
