const { sanitizeName } = require("./utils.js");

export const getMangoFields = (user) => {
  // const names = {
  //   theftSeen: "¿Has visto los hechos en vivo?",
  //   theftCount: "Autor de los hechos",
  //   looseArticles: "Pila de artículos",
  //   regularInArea: "Habitual de la zona",
  //   theftMethod: "Ha Utilizado el método...",
  //   hasImages: "¿Cargamos imágenes?",
  //   requestImages: " ",
  // };

  //Estos son los campos custom de Mango que por ahora ven todos.
  const blocks = [];

  const fields1 = [];

  const theftSeenOptions = [
    "SI LO HEMOS VISTO Y LLAMADO A LA POLICIA/VIGILANTE",
    "SI, LO HEMOS VISTO PERO NO LO HEMOS RETENIDO",
    "NO LO HEMOS VISTO PERO POLICIA NOS HA ENTREGADO O SOLICITADO ARTÍCULOS",
    "NO LO HEMOS VISTO PERO HEMOS DETECTADO FALTA DE ARTICULOS",
  ].map((x) => ({ id: sanitizeName(x), key: sanitizeName(x) }));

  const theftCountOptions = [
    "UNA PERSONA SOLA",
    "MAS DE UNA PERSONA",
    "NS/NC",
  ].map((x) => ({ id: sanitizeName(x), key: sanitizeName(x) }));

  const looseArticlesOptions = [
    "SI, GRAN CANTIDAD (MAS DE 4 ARTICULOS)",
    "NO, POCA CANTIDAD (MENOS DE 4 ARTICULOS)",
    "NS/NC",
  ].map((x) => ({ id: sanitizeName(x), key: sanitizeName(x) }));

  const theftMethodOptions = [
    "BOLSA PREPARADA",
    "DESALARMADOR",
    "HA UTILIZADO EL PROBADOR PARA PREPARARSELO",
    "PASO LOS ARTICULOS POR ENCIMA DEL ARCO DE SEGURIDAD",
    "HAN SONADO LOS ARCOS DE SEGURIDAD",
    "OCULTA ENTRE SUS PERTENENCIAS",
    "EMPLEA FUERZA PARA LLEVÁRSELOS",
    "ESTAFA",
    "INCAUTACIÓN",
    "NS/NC",
  ].map((x) => ({ id: sanitizeName(x), key: sanitizeName(x) }));

  const hasImagesOptions = [
    "Si, tenemos las imágenes guardadas y las subimos al apartado archivos",
    "Sí, subimos las grabaciones, pero no se ve lo sucedido",
    "No, tenemos cámaras en tienda, pero no sabemos el momento exacto del hurto",
    "No, tenemos testigos y no necesitamos imágenes",
    "No, no tenemos sistemas de cámaras en tienda o presentan fallos.",
    // "SI, TENEMOS IMÁGENES GUARDADAS Y LAS SUBIMOS AL APARTADO ARCHIVOS A LA APLICACIÓN",
    // "SI, TENEMOS CAMARAS EN LA TIENDA, PERO NO SE VE LO SUCEDIDO",
    // "NO, TENEMOS TESTIGOS Y NO NECESITAMOS IMÁGENES",
    // "NO, NO TENEMOS SISTEMAS DE CAMARAS EN LA TIENDA"
  ].map((x) => ({ id: sanitizeName(x), key: sanitizeName(x) }));

  const regularInAreaOptions = [
    "SI, PERSONA CONOCIDA REINCIDENTE",
    "NO",
    "NS/NC",
  ].map((x) => ({ id: sanitizeName(x), key: sanitizeName(x) }));

  fields1.push({
    attribute: "theftSeen",
    type: "enum",
    validation: "required",
    extra: {
      enumType: "theftSeen",
      options: theftSeenOptions,
    },
    size: "1/2",
  });
  fields1.push({
    attribute: "theftCount",
    type: "enum",
    validation: "required",
    extra: {
      enumType: "theftCount",
      options: theftCountOptions,
      customSort: () => 1, //Orden establecido por nosotros
    },
    size: "1/2",
  });

  blocks.push(fields1);

  const fields2 = [];

  fields2.push({
    attribute: "looseArticles",
    type: "enum",
    validation: "required",
    extra: {
      enumType: "looseArticles",
      options: looseArticlesOptions,
      customSort: () => 1, //Orden establecido por nosotros
    },
    size: "1/3",
  });
  fields2.push({
    attribute: "regularInArea",
    type: "enum",
    validation: "required",
    extra: {
      enumType: "regularInArea",
      options: regularInAreaOptions,
      customSort: () => 1, //Orden establecido por nosotros
    },
    size: "1/3",
  });
  fields2.push({
    attribute: "theftMethod",
    type: "enum",
    validation: "required",
    extra: {
      enumType: "theftMethod",
      options: theftMethodOptions,
    },
    size: "1/3",
  });

  blocks.push(fields2);

  const fields3 = [];

  fields3.push({
    attribute: "hasImages",
    type: "enum",
    validation: "required",
    extra: {
      enumType: "hasImages",
      options: hasImagesOptions,
      customSort: () => 1, //Orden establecido por nosotros
    },
    size: "2/3",
  });

  if (["admin", "manager"].includes(user.role)) {
    fields3.push({
      attribute: "requestImages",
      hideLabel: true,
      type: "custom",
      validation: "",
      template: "requestImages",
      size: "1/3",
    });
  }

  blocks.push(fields3);

  // blocks.forEach((b) => b.forEach((f) => (f.realName = names[f.attribute])));

  return blocks;
};
