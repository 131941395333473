export const sanitizeName = (name) => {
  let sanitized = name;

  sanitized = sanitized.replace(/[áàä]/g, "a");
  sanitized = sanitized.replace(/[éèë]/g, "e");
  sanitized = sanitized.replace(/[íìï]/g, "i");
  sanitized = sanitized.replace(/[óòö]/g, "o");
  sanitized = sanitized.replace(/[úùü]/g, "u");

  sanitized = sanitized.replace(/[ÁÀÄ]/g, "A");
  sanitized = sanitized.replace(/[ÉÈË]/g, "E");
  sanitized = sanitized.replace(/[ÍÌÏ]/g, "I");
  sanitized = sanitized.replace(/[ÓÒÖ]/g, "O");
  sanitized = sanitized.replace(/[ÚÙÜ]/g, "U");

  sanitized = sanitized.replace(/[ç]/g, "c");
  sanitized = sanitized.replace(/[Ç]/g, "C");

  sanitized = sanitized.replace(/[ñ]/g, "n");
  sanitized = sanitized.replace(/[Ñ]/g, "N");

  //Finalmente reemplazo todos aquellos caracteres que no haya procesado especialmente
  sanitized = sanitized.replace(/[^a-z0-9]|\s+|\r?\n|\r/gim, "_");

  return sanitized;
};
