const { sanitizeName } = require("./utils.js");

export const isFundGrubeCustomer = (customerId) => {
  return customerId === process.env.VUE_APP_FUND_GRUBE_ID;
};

export const getFundGrubeFields = () => {
  // const names = {
  //     "caseCreator": "Creador caso",
  //     "stealType": "Tipo de hurto"
  // }

  //Estos son los campos custom de Fund Grube que por ahora ven todos.
  const blocks = [];

  const fields1 = [];

  const caseCreatorOptions = [
    "Gerente",
    "Adjunto",
    "Responsable",
    "Abogado",
    "Seguridad",
  ].map((x) => ({ id: sanitizeName(x), alias: x }));

  const stealTypeOptions = ["FALSO", "VERDADERO", "NS/NC"].map((x) => ({
    id: sanitizeName(x),
    alias: x,
  }));

  fields1.push({
    attribute: "caseCreator",
    type: "select",
    validation: "required",
    extra: { options: caseCreatorOptions },
    size: "1/2",
  });
  fields1.push({
    attribute: "stealType",
    type: "select",
    validation: "required",
    extra: { options: stealTypeOptions },
    size: "1/2",
  });

  blocks.push(fields1);

  blocks.forEach((b) =>
    b.forEach((f) => {
      // f.realName = names[f.attribute];
      f.visibility = (data) => {
        return data && data.customer && isFundGrubeCustomer(data.customer.id);
      };
    })
  );

  return blocks;
};
