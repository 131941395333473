var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('FormPage',{attrs:{"collectionName":_vm.collectionName,"tabs":_vm.tabs,"prevalidate":_vm.prevalidate,"id":_vm.$route.params.id,"defaultActiveTab":_vm.defaultActiveTab,"pathPrefix":"main","showDeleteButton":!(_vm.isCustomer || _vm.isSuperCustomer || _vm.isLawyer),"headerData":{ entity: _vm.entity, lawyer: _vm.lawyer },"formIsReadOnly":_vm.isCustomer,"customModelProcessor":_vm.customModelProcessor},scopedSlots:_vm._u([{key:"formHeader",fn:function(ref){
var entity = ref.entity;
var lawyer = ref.lawyer;
return [(entity)?_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('h1',[_c('strong',{style:({ color: _vm.statusData.color })},[_vm._v(" "+_vm._s(entity.cid)+" - "+_vm._s(entity.customer.alias)+" ")])]),_c('h6',[_vm._v(" "+_vm._s(_vm.$t(("enums.caseStatuses." + (_vm.statusData.key))))+" | "+_vm._s(_vm.$moment(entity.datetime.toDate()).format("DD-MM-YYYY HH:mm"))+" ")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full text-right"},[_c('h6',[_c('strong',[_vm._v(_vm._s(entity.createdBy.email))]),_c('br'),_vm._v(" "+_vm._s(_vm.$moment(entity.createdAt.toDate()).format("DD-MM-YYYY HH:mm"))+" ")])]),(lawyer)?_c('div',{staticClass:"vx-col sm:w-1/1 w-full mb-2 mt-2"},[_vm._v(" "+_vm._s(lawyer.alias)+" "),(lawyer.phone)?_c('a',{attrs:{"href":("tel:" + (lawyer.phone))}},[_vm._v(" - "),_c('feather-icon',{attrs:{"icon":"PhoneIcon","svgClasses":"w-4 h-4"}}),_vm._v(" "+_vm._s(lawyer.phone)+" ")],1):_vm._e(),(lawyer.email)?_c('a',{attrs:{"href":("mailto:" + (lawyer.email))}},[_vm._v(" - "),_c('feather-icon',{attrs:{"icon":"MailIcon","svgClasses":"w-4 h-4"}}),_vm._v(" "+_vm._s(lawyer.email)+" ")],1):_vm._e()]):_vm._e(),_c('vs-divider')],1):_vm._e(),(entity && entity.deleted)?_c('vs-alert',{staticClass:"mt-5 mb-5",attrs:{"active":"true","color":"danger","icon-pack":"feather","icon":"icon-trash"}},[_vm._v(" "+_vm._s(_vm.$t("messages.errors.caseRemoved"))+" ")]):_vm._e()]}},{key:"requestImages",fn:function(ref){
var model = ref.model;
return [(_vm.checkIfShowImages(model))?_c('vs-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.createPDFRequestImages()}}},[_vm._v(" "+_vm._s(_vm.$t("cases.labels.requestImagesButton"))+" ")]):_vm._e()]}},{key:"witnessSearcher",fn:function(ref){return [_c('vx-input-group',[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"code","filterable":false,"options":_vm.witnessesSearch,"reduce":function (option) { return ({
            id: option.objectID,
            code: option.code,
            name: option.name,
            lastname: option.lastname,
            email: option.email,
            mobile: option.mobile,
          }); },"clearable":true,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"name":"Witness","placeholder":_vm.$t('cases.placeholders.witnesses')},on:{"search":_vm.witnessSearch},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.name)+" "+_vm._s(option.lastname)+" ("+_vm._s(option.email)+") ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.name)+" "+_vm._s(option.lastname)+" ("+_vm._s(option.email)+") ")]}}],null,true),model:{value:(_vm.witness),callback:function ($$v) {_vm.witness=$$v},expression:"witness"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text btn-addon"},[_c('vs-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addWitness(_vm.witness)}}},[_vm._v(" "+_vm._s(_vm.$t("witnesses.buttons.add"))+" ")])],1)])],2)]}},{key:"townLocation",fn:function(ref){
          var model = ref.model;
return [_c('vs-input',{staticClass:"w-full border-0",attrs:{"disabled":true,"value":_vm.findTownLocation(model.town)}})]}},{key:"managerFilesListing",fn:function(ref){
          var files = ref.files;
return [(files.length)?_c('vs-table',{staticClass:"w-full",attrs:{"stripe":"","data":_vm.sortedFiles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',[_c('vs-button',{staticClass:"mr-3 float-left",attrs:{"color":"danger","type":"flat","icon-pack":"feather","icon":"icon-trash","title":_vm.$t('cases.files.tooltips.remove', {
                  name: data[indextr].name,
                })},on:{"click":function($event){return _vm.confirmRemoveFile(
                  data[indextr].id,
                  data[indextr].name,
                  'files'
                )}}}),_c('span',{staticClass:"float-left py-2"},[_vm._v(" "+_vm._s(_vm.$moment(data[indextr].createdAt.toDate()).format( "DD-MM-YYYY HH:mm" ))+" ")])],1),_c('vs-td',[_c('vs-button',{staticClass:"float-left",attrs:{"color":"primary","type":"flat","target":"_blank","href":data[indextr].url,"title":_vm.$t('cases.files.tooltips.view', { name: data[indextr].name })}},[_vm._v(" "+_vm._s(data[indextr].name)+" ")])],1),_c('vs-td',[_c('vs-button',{staticClass:"mr-3 float-right",attrs:{"color":"dark","type":"flat","icon-pack":"feather","icon":"icon-arrow-down","title":_vm.$t('cases.files.tooltips.download', {
                  name: data[indextr].name,
                })},on:{"click":function($event){return _vm.downloadWithVueResource(data[indextr].url, data[indextr].name)}}},[_vm._v(" "+_vm._s(_vm.$t("cases.files.buttons.download"))+" ")])],1)],1)})}}],null,true)},[_c('template',{slot:"thead"},[_c('vs-th',{nativeOn:{"click":function($event){return _vm.toggleFilesSort('createdAt')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.files.fields.date"))+" "),(_vm.filesSort.createdAt)?_c('feather-icon',{attrs:{"icon":_vm.filesSort.createdAt === 1 ? 'ArrowUpIcon' : 'ArrowDownIcon'}}):_vm._e()],1),_c('vs-th',{nativeOn:{"click":function($event){return _vm.toggleFilesSort('name')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.files.fields.name"))+" "),(_vm.filesSort.name)?_c('feather-icon',{attrs:{"icon":_vm.filesSort.name === 1 ? 'ArrowUpIcon' : 'ArrowDownIcon'}}):_vm._e()],1),_c('vs-th',{staticClass:"float-right"},[_c('vs-button',{staticClass:"mr-3 float-right",attrs:{"color":"primary","icon-pack":"feather","icon":"icon-archive"},on:{"click":function($event){return _vm.downloadZipFiles('files')}}},[_vm._v(" "+_vm._s(_vm.$t("cases.files.buttons.downloadAll"))+" ")])],1)],1)],2):_vm._e()]}}],null,false,2917722237)}):_c('div',{staticClass:"custom-absolute-loading"},[_c('div',{staticClass:"loading"},[_c('div',{staticClass:"effect-1 effects"}),_c('div',{staticClass:"effect-2 effects"}),_c('div',{staticClass:"effect-3 effects"})])])}
var staticRenderFns = []

export { render, staticRenderFns }