import axios from "axios";
import firebase from "firebase/app";

const apiKey = "PENAL_RETAIL_API_KEY";

//TODO: Mandar esto al servidor
export default {
  find: async (name, { text, filter, fields, page, perPage, sort }) => {
    const query = [];
    if (perPage) {
      query.push({ key: "perPage", value: perPage });
      if (page) {
        query.push({ key: "page", value: page });
      }
    }
    if (sort) {
      query.push({ key: "sort", value: sort });
    }
    if (text) {
      query.push({ key: "text", value: text });
    }
    const response = await axios.post(
      `https://g6wys9qsv0.execute-api.eu-west-1.amazonaws.com/Prod/list/${name}${
        query.length
          ? `?${query
              .map((x) => `${x.key}=${encodeURIComponent(x.value)}`)
              .join("&")}`
          : ""
      }`,
      { filter, fields },
      {
        headers: {
          Authorization: `Api-Key ${apiKey}`,
          "penal-retail-user": firebase.auth().currentUser.uid,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  },
};
