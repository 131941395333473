<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    :defaultActiveTab="defaultActiveTab"
    pathPrefix="main"
    :showDeleteButton="!(isCustomer || isSuperCustomer || isLawyer)"
    :headerData="{ entity, lawyer }"
    v-if="isReady"
    :formIsReadOnly="isCustomer"
    :customModelProcessor="customModelProcessor"
  >
    <template v-slot:formHeader="{ entity, lawyer }">
      <div class="vx-row" v-if="entity">
        <div class="vx-col sm:w-1/3 w-full">
          <h1>
            <strong :style="{ color: statusData.color }">
              {{ entity.cid }} - {{ entity.customer.alias }}
            </strong>
          </h1>
          <h6>
            {{ $t(`enums.caseStatuses.${statusData.key}`) }} |
            {{ $moment(entity.datetime.toDate()).format("DD-MM-YYYY HH:mm") }}
          </h6>
        </div>
        <div class="vx-col sm:w-2/3 w-full text-right">
          <h6>
            <strong>{{ entity.createdBy.email }}</strong>
            <br />
            {{ $moment(entity.createdAt.toDate()).format("DD-MM-YYYY HH:mm") }}
          </h6>
        </div>
        <div class="vx-col sm:w-1/1 w-full mb-2 mt-2" v-if="lawyer">
          {{ lawyer.alias }}
          <a :href="`tel:${lawyer.phone}`" v-if="lawyer.phone">
            - <feather-icon icon="PhoneIcon" svgClasses="w-4 h-4" />
            {{ lawyer.phone }}
          </a>
          <a :href="`mailto:${lawyer.email}`" v-if="lawyer.email">
            - <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            {{ lawyer.email }}
          </a>
        </div>

        <vs-divider />
      </div>

      <vs-alert
        active="true"
        class="mt-5 mb-5"
        color="danger"
        icon-pack="feather"
        icon="icon-trash"
        v-if="entity && entity.deleted"
      >
        {{ $t("messages.errors.caseRemoved") }}
      </vs-alert>
    </template>
    <template v-slot:requestImages="{ model }">
      <vs-button
        color="primary"
        @click="createPDFRequestImages()"
        v-if="checkIfShowImages(model)"
      >
        {{ $t("cases.labels.requestImagesButton") }}
      </vs-button>
    </template>
    <template v-slot:witnessSearcher="{}">
      <vx-input-group>
        <v-select
          label="code"
          @search="witnessSearch"
          :filterable="false"
          :options="witnessesSearch"
          :reduce="
            (option) => ({
              id: option.objectID,
              code: option.code,
              name: option.name,
              lastname: option.lastname,
              email: option.email,
              mobile: option.mobile,
            })
          "
          :clearable="true"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="witness"
          class="mb-4 md:mb-0"
          v-validate="'required'"
          name="Witness"
          :placeholder="$t('cases.placeholders.witnesses')"
        >
          <template slot="option" slot-scope="option">
            {{ option.code }} - {{ option.name }} {{ option.lastname }} ({{
              option.email
            }})
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.code }} - {{ option.name }} {{ option.lastname }} ({{
              option.email
            }})
          </template>
        </v-select>
        <template slot="append">
          <div class="append-text btn-addon">
            <vs-button color="primary" @click="addWitness(witness)">
              {{ $t("witnesses.buttons.add") }}
            </vs-button>
          </div>
        </template>
      </vx-input-group>
    </template>
    <template v-slot:townLocation="{ model }">
      <vs-input
        class="w-full border-0"
        :disabled="true"
        :value="findTownLocation(model.town)"
      />
    </template>
    <template v-slot:managerFilesListing="{ files }">
      <vs-table stripe class="w-full" :data="sortedFiles" v-if="files.length">
        <template slot="thead">
          <vs-th @click.native="toggleFilesSort('createdAt')">
            {{ $t("cases.files.fields.date") }}
            <feather-icon
              :icon="
                filesSort.createdAt === 1 ? 'ArrowUpIcon' : 'ArrowDownIcon'
              "
              v-if="filesSort.createdAt"
            ></feather-icon>
          </vs-th>
          <vs-th @click.native="toggleFilesSort('name')">
            {{ $t("cases.files.fields.name") }}
            <feather-icon
              :icon="filesSort.name === 1 ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              v-if="filesSort.name"
            ></feather-icon>
          </vs-th>
          <vs-th class="float-right">
            <vs-button
              color="primary"
              class="mr-3 float-right"
              @click="downloadZipFiles('files')"
              icon-pack="feather"
              icon="icon-archive"
            >
              {{ $t("cases.files.buttons.downloadAll") }}
            </vs-button>
          </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <vs-button
                color="danger"
                type="flat"
                class="mr-3 float-left"
                @click="
                  confirmRemoveFile(
                    data[indextr].id,
                    data[indextr].name,
                    'files'
                  )
                "
                icon-pack="feather"
                icon="icon-trash"
                :title="
                  $t('cases.files.tooltips.remove', {
                    name: data[indextr].name,
                  })
                "
              />
              <span class="float-left py-2">
                {{
                  $moment(data[indextr].createdAt.toDate()).format(
                    "DD-MM-YYYY HH:mm"
                  )
                }}
              </span>
            </vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="flat"
                class="float-left"
                target="_blank"
                :href="data[indextr].url"
                :title="
                  $t('cases.files.tooltips.view', { name: data[indextr].name })
                "
              >
                {{ data[indextr].name }}
              </vs-button>
            </vs-td>
            <vs-td>
              <vs-button
                color="dark"
                type="flat"
                class="mr-3 float-right"
                @click="
                  downloadWithVueResource(data[indextr].url, data[indextr].name)
                "
                icon-pack="feather"
                icon="icon-arrow-down"
                :title="
                  $t('cases.files.tooltips.download', {
                    name: data[indextr].name,
                  })
                "
              >
                {{ $t("cases.files.buttons.download") }}
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </template>
  </FormPage>
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import firebase from "firebase/app";
import { db } from "@/firebase/firebaseConfig";

import vSelect from "vue-select";

import FormPage from "@/components/penal-retail-helpers/FormPage";
import formConfig from "@/helpers/casesFormConfig";

import statuses from "@/data/cases/lists/caseStatuses";

import complaintRoles from "@/data/cases/lists/complaintRoles.json";
import trialActions from "@/data/cases/lists/trialActions.json";
import towns from "@/data/generic/municipios.json";
import communities from "@/data/generic/comunidades.json";
import provinces from "@/data/generic/provincias.json";

import { NEW_CASE_STATUS_ID, createSearchRegexp } from "@/helpers/utils";

let collectionName = "cases";

export default {
  components: {
    vSelect,
    FormPage,
  },
  computed: {
    ...mapGetters([
      "allCases",
      "allCustomers",
      "allEstablishments",
      "allLawyers",
    ]),

    sortedFiles() {
      let key = Object.keys(this.filesSort)[0];
      let isAsc = this.filesSort[key] == -1;
      return [...this.files].sort((a, b) => {
        let minVal = (isAsc ? a : b)[key];
        let maxVal = (isAsc ? b : a)[key];
        if (key === "name") {
          return minVal.localeCompare(maxVal);
        } else if (key === "createdAt") {
          return minVal.toDate() - maxVal.toDate();
        } else {
          return minVal - maxVal;
        }
      });
    },

    statusData() {
      return this.entity && this.entity.caseStatus
        ? statuses.find((x) => x.id === this.entity.caseStatus.id)
        : null;
    },

    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    isLawyer() {
      return this.$store.state.AppActiveUser.role === "lawyer";
    },
    isReady() {
      return (
        !!this.entity &&
        !!this.files &&
        !!this.complaintFiles &&
        !!this.judgmentFiles &&
        !!this.customerWitnesses &&
        !!this.relatedCases1 &&
        !!this.relatedCases2 &&
        !!this.adjournments &&
        !!this.actions &&
        !!this.colaboratorsActions
      );
    },
    defaultActiveTab() {
      if (this.entity && this.entity.ticket && !(this.files || []).length) {
        return 2;
      }
      return 0;
    },
    lawyer() {
      return this.entity && this.entity.lawyer
        ? this.allLawyers.find((x) => x.id === this.entity.lawyer.id)
        : null;
    },
    caseBands() {
      const x = "Bands";
      let col = this[x.toLowerCase()];
      let iCol = this[`cases${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => iCol.some((iy) => iy.entityId === y.id));
      }
    },
    caseCriminals() {
      const x = "Criminals";
      let col = this[x.toLowerCase()];
      let iCol = this[`cases${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => iCol.some((iy) => iy.entityId === y.id));
      }
    },
    caseVehicles() {
      const x = "Vehicles";
      let col = this[x.toLowerCase()];
      let iCol = this[`cases${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => iCol.some((iy) => iy.entityId === y.id));
      }
    },
    notCaseBands() {
      const x = "Bands";
      let col = this[x.toLowerCase()];
      let iCol = this[`cases${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => !iCol.some((iy) => iy.entityId === y.id));
      }
    },
    notCaseCriminals() {
      const x = "Criminals";
      let col = this[x.toLowerCase()];
      let iCol = this[`cases${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => !iCol.some((iy) => iy.entityId === y.id));
      }
    },
    notCaseVehicles() {
      const x = "Vehicles";
      let col = this[x.toLowerCase()];
      let iCol = this[`cases${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => !iCol.some((iy) => iy.entityId === y.id));
      }
    },

    relatedCases() {
      const relations = [].concat(
        this.relatedCases1.map((x) => ({
          relationId: x.id,
          relatedCaseId: x.case2,
        })),
        this.relatedCases2.map((x) => ({
          relationId: x.id,
          relatedCaseId: x.case1,
        }))
      );

      return relations
        .map((x) => {
          const caseData = this.allCases.find((c) => c.id === x.relatedCaseId);
          return caseData
            ? { id: x.relationId, caseId: caseData.id, ...caseData }
            : undefined;
        })
        .filter((x) => !!x)
        .sort(
          (a, b) =>
            a.datetime.toDate().getTime() - b.datetime.toDate().getTime()
        );
    },
  },
  watch: {
    entity() {
      if (this.entity) {
        this.entity = this.customModelProcessor(this.entity);

        this.$bind(
          "customerWitnesses",
          db
            .collection("witnesses")
            .where("customer.id", "==", this.entity.customer.id)
            .where("deleted", "==", false)
        );
      }
    },
  },
  created() {
    this.$bind(
      "entity",
      db.collection(collectionName).doc(this.$route.params.id),
      {
        wait: true,
      }
    );

    this.$bind(
      "witnesses",
      db
        .collection("witnesses")
        .where("cases", "array-contains", this.$route.params.id)
        .where("deleted", "==", false),
      { wait: true }
    );

    ["files", "complaintFiles", "judgmentFiles"].forEach((x) => {
      this.$bind(
        x,
        db
          .collection(x)
          .where("case", "==", this.$route.params.id)
          .where("deleted", "==", false),
        { wait: true }
      );
    });

    ["Bands", "Criminals", "Vehicles"].forEach((x) => {
      const collectionName = x.toLowerCase();
      this.$bind(
        collectionName,
        db.collection(collectionName).where("deleted", "==", false),
        { wait: true }
      );
      this.$bind(
        `cases${x}`,
        db.collection(`cases${x}`).where("caseId", "==", this.$route.params.id),
        { wait: true }
      );
    });

    if (!this.isCustomer) {
      this.$bind(
        "relatedCases1",
        db
          .collection("relatedCases")
          .where("case1", "==", this.$route.params.id),
        { wait: true }
      );
      this.$bind(
        "relatedCases2",
        db
          .collection("relatedCases")
          .where("case2", "==", this.$route.params.id),
        { wait: true }
      );
    } else {
      this.relatedCases1 = [];
      this.relatedCases2 = [];
    }

    this.$bind(
      "adjournments",
      db.collection(`cases/${this.$route.params.id}/adjournments`),
      { wait: true }
    );

    this.$bind(
      "actions",
      db.collection(`cases/${this.$route.params.id}/actions`).orderBy("date"),
      { wait: true }
    );
    this.$bind(
      "colaboratorsActions",
      db
        .collection(`cases/${this.$route.params.id}/colaboratorsActions`)
        .orderBy("date"),
      { wait: true }
    );
  },
  data() {
    const tabs = formConfig(this, this.$store.state.AppActiveUser.role, false);

    return {
      requestImages: false,
      filesSort: { name: 1 },
      ...["Bands", "Criminals", "Vehicles"]
        .map((x) => {
          const collectionName = x.toLowerCase();
          return {
            [collectionName]: null,
            [`cases${x}`]: null,
          };
        })
        .reduce((a, b) => ({ ...a, ...b })),
      relatedCases1: null,
      relatedCases2: null,
      witness: null,
      customerWitnesses: [],
      witnesses: null,
      witnessesSearch: [],
      entity: null,
      files: null,
      complaintFiles: null,
      judgmentFiles: null,
      adjournments: null,
      actions: null,
      colaboratorsActions: null,

      collectionName,

      tabs: tabs.map((t) => ({
        name: `${collectionName}.tabs.${t.name}`,
        rows: t.rows,
      })),
    };
  },
  methods: {
    checkIfShowImages(model) {
      //Mostramos el botón de solicitud de imagenes si:
      //1 - Somos admins o managers
      //2 - No hemos pedido la imagen ahora (bandera temporal)
      //3 - No esta marcada la bandera temporal en la entidad (todavía no se procesó)
      //4 - No existe un fichero subido marcado como que es solicitud de imagenes
      const isAdminOrManager = ["admin", "manager"].includes(
        this.$store.state.AppActiveUser.role
      );
      return (
        isAdminOrManager &&
        !this.requestImages &&
        !model.requestImages &&
        !(this.files || []).some((x) => x.isRequestImages)
      );
    },
    async createPDFRequestImages() {
      this.requestImages = true;

      await db
        .collection("cases")
        .doc(this.$route.params.id)
        .update({
          updatedAt: firebase.firestore.Timestamp.now(),
          updatedBy: {
            email: this.$store.state.AppActiveUser.email,
            id: this.$store.state.AppActiveUser.uid,
          },
          requestImages: true,
        });

      this.requestImages = false;
      this.$vs.notify({
        title: "Generando y subiendo pdf",
        color: "success",
      });
    },

    findTownLocation(townData) {
      let town = towns.find((t) => t.id === townData.id);
      let province = provinces.find((p) => p.id === town.province) || {
        name: "",
      };
      let comunity = communities.find((c) => c.id === town.community) || {
        name: "",
      };
      return `${province.name} - ${comunity.name}`;
    },

    toggleFilesSort(key) {
      if (this.filesSort[key]) {
        this.filesSort[key] = -this.filesSort[key];
      } else {
        this.filesSort = { [key]: 1 };
      }
    },

    forceFileDownload(response, name) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    downloadWithVueResource(url, name) {
      this.$http({
        method: "get",
        url: url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, name);
        })
        .catch(() => console.log("error occured"));
    },

    downloadZipFiles(type) {
      const caseId = this.$route.params.id;
      window.open(
        `${process.env.VUE_APP_FUNCTIONS_URL}/downloadZip?caseId=${caseId}&type=${type}`
      );
    },

    confirmRemoveFile(id, name, fileCollectionName) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("modals.fileRemoveConfirmation.title", { name }),
        text: this.$t("modals.fileRemoveConfirmation.description"),
        acceptText: this.$t("buttons.confirm"),
        cancelText: this.$t("buttons.cancel"),

        accept: (id) => {
          let fileRef = db.collection(fileCollectionName).doc(id);

          fileRef
            .update({
              deleted: true,
            })
            .then(() => {
              console.log("File deleted");
            })
            .catch((error) => {
              console.log("Error deleting case file from database", error);
            });
        },
        parameters: id,
      });
    },

    customModelProcessor(model) {
      if (!model.caseStatus) {
        let newCaseStatus = statuses.find((s) => s.id === NEW_CASE_STATUS_ID);
        model.caseStatus = {
          id: newCaseStatus.id,
          key: newCaseStatus.key,
        };
      }

      if (!model.complaintRole) {
        let defaultComplaintRole = complaintRoles.find(
          (x) => x.key === "complainant"
        );
        model.complaintRole = {
          id: defaultComplaintRole.id,
          alias: this.$t(`enums.complaintRoles.${defaultComplaintRole.key}`),
        };
      }

      if (model.trialAction === null || model.trialAction === undefined) {
        const defaultTrialAction = trialActions.find(
          (x) => x.key === "accusation"
        );
        model.trialAction = defaultTrialAction
          ? {
              id: defaultTrialAction.id,
              alias: this.$t(`enums.trialActions.${defaultTrialAction.key}`),
            }
          : null;
      }

      [
        "minors",
        "prison",
        "restrainingOrder",
        "facialRecognition",
        "companyCivilLiability",
        "injuriesCivilLiability",
        "preimplantation",
      ].forEach((att) => {
        if (model[att] === null || model[att] === undefined) {
          model[att] = false;
        }
      });

      //Soportamos datos viejos sin abogado de referencia
      if (!model.lawyer) {
        let lawyer = null;
        if (model.customer && model.establishment) {
          let establishment = model.establishment.id
            ? this.allEstablishments.find(
                (x) => x.id === model.establishment.id
              )
            : null;

          if (establishment && establishment.lawyer) {
            lawyer = establishment.lawyer;
          } else {
            let customer = this.allCustomers.find(
              (x) => x.id === model.customer.id
            );

            lawyer = customer.lawyer;
          }
        }

        model.lawyer = lawyer;
      }

      return model;
    },
    prevalidate() {
      return false;
    },

    //---------- TESTIGOS ---------------
    witnessSearch(val) {
      if (val.length > 0) {
        const list = this.customerWitnesses
          .filter((x) => !this.witnesses.some((y) => y.id === x.id))
          .map((x) => ({
            ...x,
            objectID: x.id,
            searchValue: [x.name, x.lastname, x.email, x.code]
              .filter((x) => !!x)
              .join(" "),
          }));
        this.witnessesSearch = createSearchRegexp(
          list,
          val,
          "searchValue"
        ).filter((x, i) => i < 50);
      } else {
        this.witnessesSearch = [];
      }
    },

    addWitness(val) {
      if (val) {
        db.collection("witnesses")
          .doc(val.id)
          .update({
            cases: firebase.firestore.FieldValue.arrayUnion(
              this.$route.params.id
            ),
          });

        this.witness = null;
      }
    },
    //---------- TESTIGOS ---------------
  },
};
</script>
